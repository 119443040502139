import React, { useState, useEffect } from "react";
import HeroHeader from "./ui/headers/heroHeader";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

function AuthorizationPage() {
  const navigate = useNavigate();
  useEffect(() => {
    // Add a class to the body element when the component mounts
    document.body.classList.add("home");
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const { data } = await axios.post(`/isUserAuthorized`, {
        password: password,
      });
      console.log(data);
      toast.success(data.data.description);
      navigate("/home");
    } catch (error) {
      toast.error("Falsches Passwort!");
    }
    setIsLoading(false);
    setPassword("");
  };
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  return (
    <main className="main">
      <div className="container pb-10 mb-2">
        <HeroHeader ctaText="Zum Newsletter" ctaLink="/" customSubline ="Sehr bald zurück, sobald alle gelisteten Shops & Händler ihre Inventuren abgeschlossen haben & wieder geöffnet sind. Über unseren Newsletter immer informiert bleiben." />
      </div>

      <div className="container" style={{ marginBottom: "5rem" }}>
        <div
          className="formContainer"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <form
            onSubmit={handleSubmit}
            style={{
              display: true ? "block" : "none",
            }}
          >
            <div
              className="form-group"
              style={{ width: "30vw", minWidth: "300px", marginBottom: "2rem", fontFamily: "Poppins" }}
            >
              <label>Dein Passwort</label>
              <input
                type="text"
                className="form-control"
                id="username"
                required
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </div>

            <button
              className="btn btn-primary"
              style={{
                width: `100%`,
                backgroundColor: false && "grey",
                cursor: false && "not-allowed",
              }}
              disabled={isLoading}
            >
              {isLoading ? "Senden..." : "Senden"}
            </button>
          </form>
        </div>
      </div>
      <div className="container">
        <div style={{fontFamily: "Poppins"}}>
        
          <Link to={"/impressum"}>Impressum</Link>{" "}
          <Link to={"datenschutz"}>Datenschutz</Link>
        </div>
      </div>
    </main>
  );
}

export default AuthorizationPage;
